import React, {useEffect, useRef} from 'react';
import {useLocation} from "react-router-dom";
import {Card, Space} from 'antd';
import * as echarts from 'echarts';
import {ScrollBoard} from '@jiaminghi/data-view-react'
import FlipNumbers from 'react-flip-numbers';
import style from './PageDrill1.module.scss';
import PageBox from "../../components/PageBox";
import TileBackground from "../../components/TileBackground";
import RedDotTitle from "../../components/RedDotTitle";
import RedDotTitleBold from "../../components/RedDotTitleBold";
import chart1 from "./components/chart1";
import chart2 from "./components/chart2";
import chart3 from "./components/chart3";
import chart4 from "./components/chart4";
import chart6 from "./components/chart6";
import DataStore from './data/四个指标.json'
import PlanetaryEngine from "../../components/decoration/planetary_engine";

interface PageDrill1Props {
    width: number;
    height: number;
}

const PageDrill1: React.FC<PageDrill1Props> = (props) => {
    const {width, height} = props

    const location = useLocation();
    const region_name = (new URLSearchParams(location.search)).get('region_name') || '核心园区';

    const _data = DataStore.filter((it: any) => {
        return it.region === region_name
    })
    const data = _data[0] || []

    const _Chart1: any = useRef();
    const domRefChart1: any = useRef();

    const _Chart2: any = useRef();
    const domRefChart2: any = useRef();


    const _Chart6: any = useRef();
    const domRefChart6: any = useRef();

    const chartsInit = () => {
        if (_Chart1.current) _Chart1.current.dispose()
        _Chart1.current = echarts.init(domRefChart1.current);
        _Chart1.current.setOption(chart1({value: data['办公面积'] || '-'}));

        if (_Chart2.current) _Chart2.current.dispose()
        _Chart2.current = echarts.init(domRefChart2.current);
        _Chart2.current.setOption(chart2({value: data['入驻企业'] || '-'}));


        if (_Chart6.current) _Chart6.current.dispose()
        _Chart6.current = echarts.init(domRefChart6.current);
        _Chart6.current.setOption(chart6({
            data: {
                '每月营业收入': data['每月营业收入'] || [],
                '每月纳税额': data['每月纳税额'] || []
            }
        }));
    };
    useEffect(() => {
        chartsInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground/>
            <PageBox id={'Page1'} width={width} height={height}>
                <div style={{position: 'absolute', left: 0, top: 0,}} className={style.background}></div>
                <div style={{
                    position: 'absolute', left: '0', top: '20px',
                    width: 'calc(100% - 40px)',
                    boxSizing: 'border-box',
                    marginLeft: '20px',
                    marginRight: '20px',
                }}>
                    <Space style={{height: '110px', display: 'flex', flexFlow: 'row', overflow: 'hidden',}}
                           styles={{item: {flex: 1,}}}
                           size={60}
                    >
                        {[
                            {
                                title: <>营业收入</>, value: `${data['营业收入'] || '-'} 亿元`,
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: <>纳税额</>, value: `${data['纳税额'] || '-'} 万元`,
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: <>服务人次</>, value: `${data['服务人次'] || '-'} 万人次`,
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: <>服务企业</>, value: `${data['服务企业'] || '-'} 万家次`,
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px', fontWeight: 'bold'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                </div>

                <div style={{position: 'absolute', left: '20px', top: '140px',}}>
                    <RedDotTitle style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginBottom: '8px',
                    }} title={'办公面积'}/>
                    <div ref={domRefChart1} style={{width: '280px', height: '160px',}}/>
                </div>

                <div style={{position: 'absolute', left: '300px', top: '140px'}}>
                    <RedDotTitle style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginBottom: '8px',
                    }} title={'入驻企业'}/>
                    <div ref={domRefChart2} style={{width: '280px', height: '160px',}}/>
                </div>

                <div style={{
                    width: '280px', height: '180px',
                    position: 'absolute', left: '20px', top: '360px',
                    alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                }}>
                    <div style={{
                        width: '100%',
                        position: 'absolute', left: '18px', top: '30px',
                    }}>
                        <PlanetaryEngine style={{transform: `scale(${280 / 500}) translate(-50%, -50%)`, opacity: .6}}/>
                    </div>
                    <RedDotTitle style={{
                        marginBottom: '8px',
                    }} title={'举办培训班场次'}/>
                    <div style={{
                        width: '100%',
                        position: 'absolute', left: '0', top: '98px',
                        color: '#FFF', fontSize: '34px', fontWeight: 'bold',
                    }}>
                        {data['举办培训班场次'] || '-'} 场
                    </div>
                </div>

                <div style={{
                    width: '280px', height: '180px',
                    position: 'absolute', left: '300px', top: '360px',
                    alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                }}>
                    <div style={{
                        width: '100%',
                        position: 'absolute', left: '18px', top: '30px',
                    }}>
                        <PlanetaryEngine style={{transform: `scale(${280 / 500}) translate(-50%, -50%)`, opacity: .6}}/>
                    </div>
                    <RedDotTitle style={{
                        marginBottom: '8px',
                    }} title={'培训人数'}/>
                    <div style={{
                        width: '100%',
                        position: 'absolute', left: '0', top: '98px',
                        color: '#FFF', fontSize: '34px', fontWeight: 'bold',
                    }}>
                        {data['培训人数'] || '-'} 人
                    </div>
                </div>

                <div style={{position: 'absolute', left: '900px', top: '140px',}}>
                    <FlipNumbers
                        numbers={(data['发布求职信息条数'] as string) || '-'}
                        height={55} width={40} color="#FFF" play
                        numberClassName={style.flip_numbers_background}
                        numberStyle={{fontSize: '28px', fontWeight: 'bold',}}
                        nonNumberClassName={style.flip_non_numbers_background}
                        nonNumberStyle={{fontSize: '28px', fontWeight: 'bold', color: 'white',}}
                    />
                </div>
                <div style={{position: 'absolute', left: '600px', top: '160px',}}>
                    <RedDotTitleBold title={'发布求职信息条数'}/>
                </div>
                <div style={{
                    position: 'absolute', left: '1220px', top: '150px',
                    color: 'white', fontSize: '32px', fontWeight: 'bold',
                }}>条
                </div>

                <div style={{position: 'absolute', left: '900px', top: '220px',}}>
                    <FlipNumbers
                        numbers={(data['提供就业岗位'] as string) || '-'}
                        height={55} width={40} color="#FFF" play
                        numberClassName={style.flip_numbers_background}
                        numberStyle={{fontSize: '28px', fontWeight: 'bold',}}
                        nonNumberClassName={style.flip_non_numbers_background}
                        nonNumberStyle={{fontSize: '28px', fontWeight: 'bold', color: 'white',}}
                    />
                </div>
                <div style={{position: 'absolute', left: '600px', top: '240px',}}>
                    <RedDotTitleBold title={'提供就业岗位'}/>
                </div>
                <div style={{
                    position: 'absolute', left: '1220px', top: '230px',
                    color: 'white', fontSize: '32px', fontWeight: 'bold',
                }}>个
                </div>

                <div style={{position: 'absolute', left: '600px', top: '320px',}}>
                    <RedDotTitle title={'企业营收TOP10'}/>
                </div>
                <div style={{position: 'absolute', left: '600px', top: '360px',}}>
                    {data['入驻企业TOP10'] && <ScrollBoard config={chart4({data: data['入驻企业TOP10']})}
                                                           style={{width: '320px', height: '220px'}}/>}
                </div>

                <div style={{position: 'absolute', left: '940px', top: '320px',}}>
                    <RedDotTitle title={'企业纳税TOP10'}/>
                </div>
                <div style={{position: 'absolute', left: '940px', top: '360px',}}>
                    {data['税收TOP10'] && <ScrollBoard config={chart3({data: data['税收TOP10']})}
                                                       style={{width: '320px', height: '220px'}}/>}
                </div>

                <div style={{position: 'absolute', left: '20px', top: '570px',}}>
                    <RedDotTitle title={'营业收入/纳税额'}/>
                </div>
                <div style={{position: 'absolute', left: '0', top: '580px',}}>
                    <div ref={domRefChart6} style={{width: '1260px', height: '240px',}}/>
                </div>
            </PageBox>
        </>
    );
}

export default PageDrill1;
