import React, {CSSProperties, ReactNode, useEffect, useState} from 'react';

interface PageBoxProps {
    id?: string
    className?: string | undefined;
    children: ReactNode;
    width: number;
    height: number;
}

const PageBox: React.FC<PageBoxProps> = (props) => {
    const {id, className, children, width, height} = props

    // 这里需要尽早计算缩放，避免闪烁
    const getScreen = () => {
        const screen = document.documentElement.clientWidth / document.documentElement.clientHeight < width / height
            ? document.documentElement.clientWidth / width
            : document.documentElement.clientHeight / height;
        return screen + 0.001
    }
    const [scale, setScale] = useState<number>(getScreen())
    const handleScreenAuto = () => {
        setScale(getScreen())
    }

    useEffect(() => {
        handleScreenAuto()
        window.addEventListener("resize", handleScreenAuto);
        return () => window.removeEventListener("resize", handleScreenAuto);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height]);
    const styles: CSSProperties = {
        transform: `scale(${scale}) translate(-50%, -50%)`,
        WebkitTransform: `scale(${scale}) translate(-50%, -50%)`,
        width: width, // 设计稿宽度
        height: height, // 设计稿高度

        backgroundPosition: 'center',
        backgroundSize: 'cover',
        overflow: 'hidden',

        display: 'inline-block',
        transformOrigin: '0 0',
        position: 'absolute',
        left: '50%',
        top: '50%',
    }
    return (
        <div id={id} className={`${className || ''}`} style={styles}>
            {children}
        </div>
    );
}

export default PageBox;
