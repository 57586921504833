import 'echarts-liquidfill'

const generateSequence = (start: number, end: number, count: number) => {
    return Array.from({length: count}, (v, i) => start + (end - start) * i / (count - 1));
}

interface Chart1Props {
    value: string;
}

const chart1 = (props: Chart1Props) => {
    const {value} = props

    const n = 0.6;
    let data = generateSequence(n - (n * .6), n, 4).reverse()
    data[0] = n
    return {
        series: [{
            type: 'liquidFill',
            data: data,
            radius: '90%', // 半径占宽度的比值
            label: {
                color: 'rgba(23, 95, 190, 0.8)',
                insideColor: 'rgba(255,255,255,0.8)',
                normal: {
                    formatter: () => {
                        return `${value} ㎡`
                    },
                    fontSize: 22,
                }
            },
            outline: {
                itemStyle: {
                    borderWidth: 5,
                    borderColor: 'rgba(23, 95, 190, 0.8)',
                    shadowBlur: 10,
                    shadowColor: 'rgba(23, 95, 190)'
                }
            },
        }],
    };
};
export default chart1
