import React, {useEffect, useRef} from 'react';
import './TileBackground.scss'

interface TileBackgroundProps {
}

const TileBackground: React.FC<TileBackgroundProps> = () => {
    const domRef: any = useRef();

    // 计算背景平铺的方向
    const rootScreen = () => {
        const screen = document.documentElement.clientWidth / document.documentElement.clientHeight;
        if (screen > 1920 / 1080) {
            domRef.current.style.transform = 'rotateY(180deg)'
        } else {
            domRef.current.style.transform = 'rotateX(180deg)'
        }
    }

    useEffect(() => {
        rootScreen()
        window.addEventListener("resize", rootScreen);
        return () => window.removeEventListener("resize", rootScreen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={domRef} className={'tile_background'}/>
    );
}

export default TileBackground;
