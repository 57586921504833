import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as echarts from "echarts";
import Page1 from "./pages/page1/Page1";
import PageDrill1 from "./pages/page_drill_1/PageDrill1";

const colorPalette = [
    '#d87c7c',
    '#919e8b',
    '#d7ab82',
    '#6e7074',
    '#61a0a8',
    '#efa18d',
    '#787464',
    '#cc7e63',
    '#724e58',
    '#4b565b'
];
echarts.registerTheme('vintage', {
    color: colorPalette,
    graph: {
        color: colorPalette
    }
});

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Page1 width={1920} height={1080}/>}/>
                    <Route path="/page1" element={<Page1 width={1920} height={1080}/>}/>
                    <Route path="/page_drill_1" element={<PageDrill1 width={1280} height={840}/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
