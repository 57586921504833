import React, {CSSProperties} from "react";
import styles from './planetary_engine.module.scss';

interface PlanetaryEngineProps {
    style?: CSSProperties | undefined;
}

const PlanetaryEngine: React.FC<PlanetaryEngineProps> = (props) => {
    const {style} = props
    return <div className={styles.planetary_engine} style={style}>
        <div className="main1 flex">
            <div className="main2">
                <div className="firstCircle positon center"></div>
                <div className="secondCircle positon center"></div>
                <div className="fourthCircle flex positon center">
                    <div className="annulusContent">
                        <div className="annulus annulus1"></div>
                        <div className="annulus annulus2"></div>
                        <div className="annulus annulus3"></div>
                        <div className="annulus annulus4"></div>
                        <div className="annulus annulus5"></div>
                        <div className="annulus annulus6"></div>
                        <div className="annulus annulus7"></div>
                        <div className="annulus annulus8"></div>
                        <div className="annulus annulus9"></div>
                        <div className="annulus annulus10"></div>
                        <div className="annulus annulus11"></div>
                        <div className="annulus annulus12"></div>
                        <div className="annulus annulus13"></div>
                        <div className="annulus annulus14"></div>
                        <div className="annulus annulus15"></div>
                        <div className="annulus annulus16"></div>
                        <div className="annulus annulus17"></div>
                        <div className="annulus annulus18"></div>
                        <div className="annulus annulus19"></div>
                        <div className="annulus annulus20"></div>
                        <div className="annulus annulus21"></div>
                        <div className="annulus annulus22"></div>
                        <div className="annulus annulus23"></div>
                        <div className="annulus annulus24"></div>
                        <div className="annulus annulus25"></div>
                        <div className="annulus annulus26"></div>
                        <div className="annulus annulus27"></div>
                        <div className="annulus annulus28"></div>
                        <div className="annulus annulus29"></div>
                        <div className="annulus annulus30"></div>
                        <div className="annulus annulus31"></div>
                        <div className="annulus annulus32"></div>
                        <div className="annulus annulus33"></div>
                        <div className="annulus annulus34"></div>
                        <div className="annulus annulus35"></div>
                        <div className="annulus annulus36"></div>
                    </div>
                </div>
                <div className="bar positon flex">
                    <div className="line line1"></div>
                    <div className="line line2"></div>
                    <div className="line line3"></div>
                    <div className="line line4"></div>
                    <div className="line line5"></div>
                    <div className="line line6"></div>
                    <div className="line line7"></div>
                    <div className="line line8"></div>
                    <div className="line line9"></div>
                    <div className="line line10"></div>
                    <div className="line line11"></div>
                </div>
            </div>
        </div>
    </div>
}
export default PlanetaryEngine