interface Chart3Props {
    data: { "name": string, "纳税额": string }[];
}

const chart3 = (props: Chart3Props) => {
    const {data} = props
    console.log('data',data)
    console.log('data.reverse()',data.reverse())
    return {
        headerBGC: 'rgba(35, 183, 229, 0.3)',
        oddRowBGC: 'rgba(35, 183, 229, 0.1)',
        evenRowBGC: 'rgba(0, 0, 0, 0.0)',
        header: ['企业名称', '纳税额',],
        rowNum: 5,
        data: [...data].reverse().map((it) => {
            return [it['name'], it['纳税额'] + ' 万元']
        })
    };
};
export default chart3