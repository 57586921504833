import React from 'react';
import './Headline.scss'

interface HeadlineProps {
    title: string,
}

const Headline: React.FC<HeadlineProps> = (props) => {
    const {title} = props
    return (
        <div id={'headline_box'}>
            <div style={{
                position: 'absolute', left: '0px', top: '5px', width: '100%',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
            }}>
                <div className={'headline_bkg'} style={{width: '800px', height: '115px',}}/>
            </div>
            <div style={{
                position: 'absolute', left: '0px', top: '15px', width: '100%',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',

            }}>
                <div className={'headline'}>{title}</div>
            </div>
        </div>
    );
}

export default Headline;
