interface Chart4Props {
    data: { "name": string, "营业收入": string }[];
}

const chart4 = (props: Chart4Props) => {
    const {data} = props
    return {
        headerBGC: 'rgba(35, 183, 229, 0.3)',
        oddRowBGC: 'rgba(35, 183, 229, 0.1)',
        evenRowBGC: 'rgba(0, 0, 0, 0.0)',
        header: ['企业名称', '营业收入',],
        rowNum: 5,
        data: [...data].reverse().map((it) => {
            return [it['name'], it['营业收入'] + ' 亿元']
        })
    };
};
export default chart4
