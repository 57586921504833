interface Chart6Props {
    data: { "每月营业收入": number[], "每月纳税额": number[] };
}

const chart6 = (props: Chart6Props) => {
    const {data} = props
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 1)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid: {
            top: 40,
            left: 60,
            right: 60,
            bottom: 20,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(35, 183, 229, 1)',
                },
            },
        },
        yAxis: [
            {
                type: 'value',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(35, 183, 229, 1)',
                    },
                },
                splitLine: { // 分隔线
                    show: false,
                },
                axisLabel: {
                    formatter: (value: number) => {
                        switch (true) {
                            case value >= 10000:
                                return value / 10000 + '万'
                            case value >= 1000:
                                return value / 1000 + '千'
                            default:
                                return value
                        }
                    }
                },
            },
            {
                type: 'value',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(35, 183, 229, 1)',
                    },
                },
                splitLine: { // 分隔线
                    show: false,
                },
                axisLabel: {
                    formatter: (value: number) => {
                        switch (true) {
                            case value >= 10000:
                                return value / 10000 + '万'
                            case value >= 1000:
                                return value / 1000 + '千'
                            default:
                                return value
                        }
                    }
                },
            }
        ],
        series: [
            {
                name: '每月营业收入',
                data: data['每月营业收入'],
                yAxisIndex: 0,
                symbol: 'circle',
                symbolSize: 8,
                type: 'line',
                emphasis: {
                    focus: 'series'
                },
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                    width: 1,
                },
                itemStyle: {
                    borderWidth: 1,
                    normal: {
                        borderColor: 'rgb(50,202,250,.4)',
                        borderWidth: 2,
                        color: 'rgb(29,146,182)',//拐点颜色
                    },
                    emphasis: {
                        color: 'rgba(50,202,250)'//hover拐点颜色定义
                    }
                },
                markPoint: {
                    data: [
                        // {type: 'max', name: 'Max'},
                    ],
                    label: {
                        color: 'rgba(255,255,255,0.8)',
                        fontSize: 10
                    },
                    itemStyle: {
                        color: 'rgba(240,80,80,0.5)',
                    },
                    symbolOffset: ['0', '-10%'],
                },
            },
            {
                name: '每月纳税额',
                data: data['每月纳税额'],
                yAxisIndex: 1,
                symbol: 'circle',
                symbolSize: 8,
                type: 'line',
                emphasis: {
                    focus: 'series'
                },
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                    width: 1,
                },
                itemStyle: {
                    borderWidth: 1,
                    normal: {
                        borderColor: 'rgb(50,202,250,.4)',
                        borderWidth: 2,
                        color: 'rgb(29,146,182)',//拐点颜色
                    },
                    emphasis: {
                        color: 'rgba(50,202,250)'//hover拐点颜色定义
                    }
                },
                markPoint: {
                    data: [
                        // {type: 'max', name: 'Max'},
                    ],
                    label: {
                        color: 'rgba(255,255,255,0.8)',
                        fontSize: 10
                    },
                    itemStyle: {
                        color: 'rgba(240,80,80,0.5)',
                    },
                    symbolOffset: ['0', '-10%'],
                },
            }
        ]
    };
};
export default chart6
