import React, {CSSProperties} from 'react';
import pic1 from './image/pic-1.svg';

interface DecorationProps {
    opacity: number
}

const Decoration: React.FC<DecorationProps> = (props) => {
    const {opacity = 1} = props
    const getStyles = () => {
        const styles: CSSProperties = {
            width: '100%', height: '100%', opacity: opacity,
            backgroundImage: `url(${pic1})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }
        return styles
    }
    return (
        <div style={getStyles()}/>
    );
}

export default Decoration;
